@import 'typography';
@import 'color';
@import 'custom_bootstrap_vars';
@import '~bootstrap/scss/bootstrap';

// project specific CSS goes here
@import './contact/index.scss';
@import './home/index.scss';
@import './membres/index.scss';
@import './navbar/index.scss';
@import './news/index.scss';
@import './our_story/index.scss';
@import './portfolio/index.scss';
@import './projets/index.scss';




///////////////
// Variables //
///////////////

// Alert colors

$white: #fff;
$mint-green: #d6e9c6;
$black: #000;
$pink: #f2dede;
$dark-pink: #eed3d7;
$red: #b94a48;

////////////
// Alerts //
////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error

.alert-debug {
  background-color: $white;
  border-color: $mint-green;
  color: $black;
}

.alert-error {
  background-color: $pink;
  border-color: $dark-pink;
  color: $red;
}

@media screen and (min-width: 768px) {
  #changeOrientationButton {
    display: none;
  }
  
}