
.team__container {
  padding: 32px 16px;

  .section--title {
    text-transform: uppercase;
    text-align: center;
    font-weight: semibold;
    margin-bottom: 40px;
  }

  .card--team__container {
    max-width: 1320px;
    display: flex;
    justify-content: center;
    // flex-direction: column;
    flex-wrap: wrap;
    gap: 40px;
    margin: 0 auto;
  }

  .card--team {
      width: 100%;
      max-width: 360px;
      height: 500px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
  }

  .card--team--content {
    height: 102px;
    width: 100%;
    position: absolute;
    bottom: 0;
    color: #fff;
    padding: 10px 24px;
    opacity: 0.8;
    transition: all 0.4s ease;
  }

  .toggle-card {
    visibility: hidden;
    position: absolute;
  }

  .toggle-card-label {
    cursor: pointer;
    width: 100%;
    text-align: center;
  }

  .card--team--content.expanded {
    height: 100%;
    padding: 48px 24px;

    .toggle-card-label {
      position: absolute;
      bottom: 2%;
      left: 0;
      transform: rotate(180deg);
    }
  }

  .card--team__name {
      font-size: 24px;
      font-weight: semi-bold;
      margin-bottom: 10px;
      text-transform: uppercase;
      margin: 0;
  }

  .card--team__job,
  .card--team__degree {
      font-size: 16px;
      margin-bottom: 16px;
      color: rgba(255, 255, 255, 0.7);
  }
}

// Architecture color
._3F5A5C-background {
  background-color: rgba(63, 90, 92, 0.8);
}

// Interior color
._5E6E60-background {
  background-color: rgba(94, 110, 96, 0.8);
}

// Lightning color
._946965-background {
  background-color: rgba(148, 105, 101, 0.8);
}

// Urban color
._C2885C-background {
  background-color: rgba(194, 136, 92, 0.8);
}

// Urban color
._000000-background {
  background-color: rgba(0, 0, 0, 0.8);
}

@media screen and (min-width: 480px) {
  .team__container {
    .card--team {
      width: 100%;
      max-width: 360px;
      height: 500px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .card--team--content {
      height: 100%;
      width: 100%;
      position: static;
      visibility: hidden;
      color: #fff;
      padding: 48px 24px;
      opacity: 0;
      transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    }

    .toggle-card,
    .toggle-card-label {
      display: none;
    }

    .card--team:hover {
      .card--team--content {
        visibility: visible;
        opacity: 1;
      }
    }
  }

}