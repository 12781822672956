@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.news__container {
  width: 100%;
  max-width: 1440px;
  padding: 0 16px;
  margin: 0 auto;
  margin-top: 5%;

  .news-title {
    text-align: center;
    text-transform: uppercase;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
    font-family: "Lato", sans-serif;
    letter-spacing: 3px;
    font-weight: 500;
  }
}

.wrapper {
  display:flex;
  flex-direction: column;
  align-items: center;
}

.tabs {
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5%;
}

.tab{
  cursor: pointer;
  padding:10px 20px;
  margin:0px 2px;
  display:inline-block;
  color:#afafaf;
  font-weight: 300;
  font-size: 1.875rem;
}

.panels{
  background:#ffffff;
  min-height:700px;
  width:100%;
  max-width:1440px;
  border-radius:3px;
  overflow:hidden;
  padding:20px;

  .award-panel {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
    gap: 4vw;
    margin-bottom: 3vw;
    display:none;
    animation: fadein .8s;

    .award-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .award-title {
        font-size: 1.575rem;
        margin-bottom: 2%;
      }

      .award-desc {
        margin-top: 2%;
        font-weight: 300;
      }

      .award-img {
        width: 35vw;
        height: auto;
      }
    }
  }
  // Media panel section
  .media-container {

    .media-cards-container { 
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
      grid-gap: 20px;
      justify-items: center;
      justify-content: center;
      margin-bottom: 3vw;



      .card-vid {
        width: 100%;
        max-width: 64.2%;
        height: 500px;
        background-color: #FFFFFF;


        img {
          width: 100%;
          height: 85%;
        }

        .media-desc {
          padding: 1rem;

          p {
            margin: 0;
            padding: 0;
            font-family: "Lato", sans-serif;
          }
        }
      }

      .card-img {
        width: 100%;
        max-width: 390px;
        background-color: #FFFFFF;
        border: 1px solid lightgray;

         img {
          width: 100%;
          // height: 85%;
        }

        video {
          height: 425px;
        }

        .media-desc {
          padding: 1rem;

          p {
            margin: 0;
            padding: 0;
            font-family: "Lato", sans-serif;
          }
        }
      }

      .card-video .media-desc {
        padding-top: 10px;
      }
    }
  }
}
.panel{
  display:none;
  animation: fadein .8s;

  .award-content {
    margin-bottom: 10%;
  }
}
@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
.panel-title{
  font-size:1.5em;
  font-weight:bold
}
.radio{
  display:none;
}
#one:checked ~ .panels #one-panel,
#three:checked ~ .panels #three-panel{
  display: block;
}


#two:checked ~ .panels #two-panel {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
  grid-gap: 20px;
  justify-content: center;
  justify-items: center;
}

#one:checked ~ .tabs #one-tab,
#two:checked ~ .tabs #two-tab,
#three:checked ~ .tabs #three-tab{
  color:#000000;
}

@media screen and (max-width: 480px) {
  .panels {
    .media-container {
      .media-cards-container {
        grid-template-columns: 1fr;

        .card-img, .card-vid {
          margin-bottom: 2.5rem;
        }

        .card-vid {
          max-width: 100%;
        }
      }
    }

    .award-panel {
      grid-template-columns: 1fr;
      
      .award-content {
        .award-img {
          width: 90vw;
        }

        .award-title {
          margin-bottom: 5%;
        }
      }

      .media-cards-container {
        grid-template-columns: 1fr;
        max-width: 100%;
      }

    }
  }

  #two:checked ~ .panels #two-panel {
    grid-template-columns: 1fr;
  }

  .tab {
    font-size: 24px;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .panels {
    .media-container {
      .media-cards-container {
        grid-template-columns: 1fr;

        .card-img, .card-vid {
          margin-bottom: 2.5rem;
        }
      }
    }

    .award-panel {
      .award-content {
        .award-img {
          width: 80vw;
        }
      }
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1350px) {
    .panels .media-container .media-cards-container {

      .card-img, .card-vid {
        margin-bottom: 2.5rem;
      }
    }

    .panels {
      .award-panel {
        .award-content {
          .award-img {
            width: 50vw;
          }
        }
      }
    }
}

// @media screen and (min-width: 1350px) and (max-width: 1920px) {
//   .panels .media-container .media-cards-container {
//     display: flex;
//     justify-content: space-between;
//     flex-wrap: wrap;
//   }
// }

.glightbox-clean .gclose {
  background-color: rgba(238, 238, 238, 0.8) !important;
  border-radius: 50% !important;
  opacity: 1 !important;

  svg {
    width: 16px !important;
  }

  path {
    fill: rgba($color: #000000, $alpha: .8) !important;
  }
}

.glightbox-clean .gnext, .glightbox-clean .gprev {
  background-color: rgba(238, 238, 238, 0.8) !important;
  border-radius: 50% !important;
  width: 32px !important;
  height: 32px !important;

  svg {
    width: 16px !important;
  }

  path {
    fill: rgba($color: #000000, $alpha: .8) !important;
  }
}

.glightbox-clean .gdesc-inner {
  margin: 0 !important;
  padding: 5px !important;

  h4 {
    margin: 0 !important;
    padding: 0 !important;
  }
}

// Fit poster image to fit
video{
  object-fit: cover;
}

// Hide next and previous buttons
.single-card-group {

  .glightbox-clean .gnext, .glightbox-clean .gprev {
    display: none !important;
  }
}

// Hide images
.hidden {
  display: none;
}

// temporary thumbnails fix
.card-img-content {
  height: 425px !important;
  width: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// Temporary fix for award panel
.award-panel {
  .media-cards-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    margin-bottom: 3vw;
    width: 100%;
    max-width: 390px;
  }

  .card-img-content {
    width: 100% !important;
    height: auto !important;
  }

  .media-desc {
    padding: 1rem 1rem 1rem 0;
  }

  .award-desc {
    margin: 0;
  }
}
