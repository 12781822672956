@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap');

body {
  scroll-behavior: smooth;
}

.project__container {
  margin-top: 72px;
}

.page-title,
.back-link {
  display: block;
  text-align: center;
  text-decoration: none;
}

.page-title {
  font-size: 40px;
  margin: 24px 0 32px;
}

.back-link {
  font-size: 24px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
  margin: 0 auto 40px;
  width: fit-content;
  text-transform: uppercase;
}

#masonry {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default: 4 columns for desktop */
  grid-auto-flow: dense; /* Fill empty space in the grid */
  gap: 10px;
}

.project__container main {
  padding-bottom: 10px;
  overflow-x: auto;
  max-width: 1440px;
  margin: 0 auto;
  min-height: 60vh;
}

.main-image {
  width: 100%;
  margin-bottom: 10px;
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: auto / span 1; /* Each item takes one column by default */
  background-size: cover;
  background-position: center;
}

.item img {
  width: 100%;
  height: auto;
  visibility: hidden;
}

.item.double-width {
  grid-column: auto / span 2; /* Make an item take two columns */
}

/* PROJECTS SPECIFIC */
.project-specifics {
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 18px;
  margin-top: 72px !important;
  display: flex;
  justify-content: center;
  gap: 16px 32px;
  flex-wrap: wrap;

  & .project-specific {
    margin-bottom: 0;
  }
}

.specific-completion {
  margin-top: 24px !important;
}

.custom-select {
  display: flex;
  align-items: center;
  margin: 40px auto;
  font-size: 1.15rem;
  padding: 8px 10px 8px 16px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 2px;
  color: #000;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
  text-align: left;
  white-space: nowrap;
  width: auto;

  .chevron-icon {
    width: 1em;
    height: 1em;
    background-image: url("https://jfaprod300924.s3.eu-west-3.amazonaws.com/media/site/icons/arrow-chevron-up-down.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 12px;
  }
}

/* PROJECT INFO */
#projects-info {
  display: none;
}

.project-info__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin: 0 auto;
  max-width: 640px;
  text-transform: uppercase;
  font-weight: 300;

  p {
    margin: 0;
  }

  .project-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .project-info-title {
    width: 300px;
  }

  .project-info-value {
    text-align: right;
  }
}

.project-overview-title {
  text-transform: uppercase;
  font-weight: 400;
  margin: 72px 0 32px;
  font-size: 1.35rem;
}

.design-development--title {
  font-weight: 400;
  font-size: 1.35rem;
}

.project-specifics {
  margin-top: 0;

  & p {
    font-weight: 300;
    font-size: 18px !important;
    margin-bottom: 0;
  }
}

.project-specificities__container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto 72px;
  padding: 0 16px;
}

.design-development__container-bg {
  background-color: black;
  color: white;

  .design-development__container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 40px 16px;
  }

  ol, ul {
    padding: 0;
  }

  .design-development-images {
    display: flex;
    flex-direction: column;
    gap: 72px;
    justify-content: center;
    list-style: none;
  }

  .design-development-images li {
    margin: 0 auto;
  }

  .design-development-images li h5 {
    text-align: center;
    font-weight: 300;
  }

  .design-development--image {
    width: 100%;
    max-height: 72vh !important;
  }

  .design-development--sub-title {
    margin: 72px 0;
    text-align: center;
    font-size: 1.35rem;
    font-weight: 100;
  }

  .back-top-btn {
    margin: 100px auto 0;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 24px;
    text-align: center;
    display: block;
    text-decoration: none;
    color: white;
    width: fit-content;
  }
}

.back-top-btn-black {
  margin: 100px auto 50px;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 24px;
  text-align: center;
  display: block;
  text-decoration: none;
  color: black;
  width: fit-content;
}

@media screen and (max-width: 768px) {
  /* For tablets, switch to 2 columns */
  #masonry {
    grid-template-columns: repeat(2, 1fr);
  }
}

.lb-data .lb-details {
  width: 100%;
}

.lb-caption {
  text-align: center;
  width: 100%;
  display: block;
}

@media screen and (max-width: 480px) {
  /* For mobile devices, switch to 1 column */
  #masonry {
    grid-template-columns: repeat(1, 1fr);
  }

  .project-info__container {
  
    .project-info {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }
  
    .project-info-value {
      text-align: left;
    }
  }

  .back-link {
    font-size: 16px;
  }
}

@media screen and (min-width: 760px) {
  .project-info__container
  .project-info {
    gap: 20px;
  }
}

.project-main-portait-image {
  max-height: 1080px;
  width: auto;
}

.main-image__container {
  width: fit-content;
  display: block;
  margin: 0 auto;
}

// GLIGHTBOX
.glightbox-clean .gclose {
  background-color: rgba(238, 238, 238, 0.8) !important;
  border-radius: 50% !important;
  opacity: 1 !important;

  svg {
    width: 16px !important;
  }

  path {
    fill: rgba($color: #000000, $alpha: .8) !important;
  }
}

.glightbox-clean .gnext, .glightbox-clean .gprev {
  background-color: rgba(238, 238, 238, 0.8) !important;
  border-radius: 50% !important;
  width: 32px !important;
  height: 32px !important;

  svg {
    width: 16px !important;
  }

  path {
    fill: rgba($color: #000000, $alpha: .8) !important;
  }
}

.glightbox-clean .gdesc-inner {
  margin: 0 !important;
  padding: 5px !important;

  h4 {
    margin: 0 !important;
    padding: 0 !important;
  }
}

// Hide next and previous buttons
.single-card-group {

  .glightbox-clean .gnext, .glightbox-clean .gprev {
    display: none !important;
  }
}

.glightbox-clean .gslide-description {
  background: transparent !important;
}

.lightbox-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;

  img, svg {
    filter: invert(100%) grayscale(1);
  }
}

@media screen and (min-width: 768px) {
  .orientation-btn {
    display: none;
  }
  
}