// Define base colors and fonts
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;400&display=swap');
$background-dark: #333; // adjust to actual color from design
$background-menu: #fff; // adjust to actual color from design
$color-text: #c3c3c3; // default text color
$color-text-hover: #000; // text color on hover
$navbar-element-color: #ddd; // color of the navbar elements

.navbar {
  background-color: transparent;
  top: 0;
  // left: 0;
  width: 100%;
  z-index: 99;
  padding: 25px;

  &.architecture {

    .menu-toggler__line {
      background-color: #3F5A5C !important;
    }

    .nav-link--language-active {
      color: #3F5A5C !important;
    }
  }

  &.interiors {

    .menu-toggler__line {
      background-color: #5E6E60 !important;
    }

    .nav-link--language-active {
      color: #5E6E60 !important;
    }
  }

  &.lighting {

    .menu-toggler__line {
      background-color: #946965 !important;
    }

    .nav-link--language-active {
      color: #946965 !important;
    }
  }

  &.urban {

    .menu-toggler__line {
      background-color: #C2885C !important;
    }

    .nav-link--language-active {
      color: #C2885C !important;
    }
  }

  .container-fluid {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-container {
      .navbar-brand {
        color: $color-text;
        text-decoration: none;
        z-index: 1000;
        position: relative;

        &:hover {
          color: $color-text-hover;
        }

        .logo {
          height: 56px;
          width: auto;
          transition: all 0.3s ease-in-out;

          &.inverted {
            filter: invert(1);
          }
        }
      }
    }

    & .centered__container {
      font-family: "Lato", sans-serif;
      font-weight: 300;
      font-style: normal;
      width: 60%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & .centered__container
    .navbar-brand-center {
      color: $color-text;
      text-decoration: none;
      font-size: clamp(1.13rem, calc(-0.17rem + 2.69vw), 1.75rem);
    }

    .right-container {
      display: flex;
      align-items: center;

      .languages-container {
        margin-right: 2em;

        ul.menu-bar {
          list-style: none;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          margin: 0;
          padding: 0;

          p,
          a {
            font-size: 1.2em;
            color: $navbar-element-color;
            text-decoration: none;
            margin: 0 !important;
            padding: 0 !important;
            transition: all 0.3s ease-in-out;
            line-height: 100%;
          }

          span.slash {
            color: $navbar-element-color;
            margin: 0 5px;
            font-size: 1.2em;
            line-height: 100%;
          }

          strong {
            font-weight: bold;
          }

          .nav-link--language {
            color: #bbb;

            // &:hover {
            //   color: white;
            // }
          }
        }
      }

      .menu-toggler-container {
        z-index: 1000;
        display: flex;
        align-items: center;

        .menu-toggler {
          background: none;
          border: none;

          span.menu-toggler__line {
            display: block;
            width: 25px;
            height: 3px;
            background-color: lighten($color: $navbar-element-color, $amount: 0);
            margin: 5px 0;
            transition: all 0.3s ease-in-out 0s;
          }

          &.toggled {
            background-color: #DBDBDB;
            border-radius: 50%;
            padding: 6px 11px 6px 9px;

            span.menu-toggler__line {
              background-color: #fff;
              width: 18px;
              height: 2px;

              &:nth-child(1) {
                transform: rotate(-45deg) translate(-4px, 6px);
              }
              &:nth-child(2) {
                opacity: 0;
              }
              &:nth-child(3) {
                transform: rotate(45deg) translate(-4px, -6px);
              }
            }
          }
        }
      }
    }

    .menu-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3em;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      background-color: $background-menu;
      transform: translate(0, -1000px);
      transition: all 0.3s ease-in-out;

      // When active class is added to the menu-container
      &.active {
        transform: translate(0, 0);
        z-index: 999;
        height: 100vh;
      }

      .menu-content {
        ul.menu {
          list-style: none;
          padding: 0;
          margin: 0;

          li.menu-item {
            a.menu-link {
              display: block;
              color: $color-text;
              text-decoration: none;
              text-align: center;
              font-size: 2em;
              font-weight: 100;
              transition: all 0.3s ease-in-out;
              letter-spacing: 2px;

              &:hover {
                font-weight: 400;
                color: $color-text-hover;
                text-shadow:
                  -1px 0 black,
                  0 1px black,
                  1px 0 black,
                  0 -1px black;

                &#portfolio-link {
                  text-shadow:
                    -1px 0 white,
                    0 1px white,
                    1px 0 white,
                    0 -1px white;
                }
              }
            }
          }
        }
      }

      .social-media-container {
        ul.menu {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          margin: 0;
          gap: 20px;
          list-style: none;
          padding: 0;
          margin: 0;

          li.menu-item {
            display: flex;
            gap: 20px;

            a.menu-link {
              text-decoration: none;
              width: 24px;
              height: 24px;
              display: block;
              display: flex;
              justify-content: center;
              align-items: center;
              // background-color: #ccc;
              border-radius: 50%;
              transition: all 0.3s ease-in-out;

              svg.social-media-icon {
                width: auto;
                height: 20px;
                transition: all 0.3s ease-in-out;
              }

              & img {
                width: 24px;
                height: 24px;
              }

              &:hover {
                filter: brightness(0);
              }
            }
          }
        }
      }
    }
  }
}

.navbar-fixed {
  position: fixed;
}

.navbar-sticky {
  position: sticky;
  background-color: white;
}

// Media queries for responsive adjustments
@media (max-width: 768px) {
  .navbar {
    padding: 15px;

    .container-fluid {
      .left-container {
        .navbar-brand {
          .logo {
            height: 48px;
          }
        }
      }

      .centered__container {
        display: none;
      }

      .right-container {
        .languages-container {
          ul.menu-bar {
            p,
            a {
              font-size: 1em;
            }
          }
        }
      }
    }
  }
}
