// Base font path
$font-path: "../fonts";


// Mixin for defining @font-face
@mixin font-face($font-family, $font-file, $weight: normal, $style: normal) {
    @font-face {
      font-family: $font-family;
      src: url('#{$font-path}/#{$font-file}.ttf') format('truetype');
      font-weight: $weight;
      font-style: $style;
    }
  }
  
  // Include font-faces for Lato
  @include font-face('Lato', 'Lato-Regular', normal, normal);
  @include font-face('Lato-Italic', 'Lato-Italic', normal, italic);
  @include font-face('Lato-Bold', 'Lato-Bold', bold, normal);
  @include font-face('Lato-BoldItalic', 'Lato-BoldItalic', bold, italic);
  @include font-face('Lato-Light', 'Lato-Light', 300, normal);
  @include font-face('Lato-LightItalic', 'Lato-LightItalic', 300, italic);
  @include font-face('Lato-Black', 'Lato-Black', 900, normal);
  @include font-face('Lato-BlackItalic', 'Lato-BlackItalic', 900, italic);
  @include font-face('Lato-Thin', 'Lato-Thin', 100, normal);
  @include font-face('Lato-ThinItalic', 'Lato-ThinItalic', 100, italic);
  
  // Reset basic styles
  * {
    font-family: 'Lato', sans-serif; // Fallback to a system font
    margin: 0;
    padding: 0;
  }
  
  // Example usage for different font weights and styles
  body {
    font-family: 'Lato', sans-serif; // Use this as your base font
  }
  
  strong, b {
    font-family: 'Lato-Bold', sans-serif;
  }
  
  em {
    font-family: 'Lato-Italic', sans-serif;
  }
  
  .light {
    font-family: 'Lato-Light', sans-serif;
  }
  
  .light-italic {
    font-family: 'Lato-LightItalic', sans-serif;
  }
  
  .bold {
    font-family: 'Lato-Bold', sans-serif;
  }
  
  .bold-italic {
    font-family: 'Lato-BoldItalic', sans-serif;
  }
  
  .black {
    font-family: 'Lato-Black', sans-serif;
  }
  
  .black-italic {
    font-family: 'Lato-BlackItalic', sans-serif;
  }
  
  .thin {
    font-family: 'Lato-Thin', sans-serif;
  }
  
  .thin-italic {
    font-family: 'Lato-ThinItalic', sans-serif;
  }
  