@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

.story__container {
  padding: 32px 0 0;
  display: flow-root;
  overflow-x: hidden;
  text-align: center;

  .title {
    text-align: center;
    text-transform: uppercase;
    padding: 40px 0 20px;
    color: #221F1F;
  }

  .content {
    padding: 40px 20px 0;
    margin-bottom: 100px;
  }

  .story-img-right {
    background-position: center;
    background-size: cover;
    width: clamp(200px, 50%, 798px);
    aspect-ratio: 1;
    margin: 0 auto 24px;
    shape-outside: circle(50%);
    shape-margin: 2rem;

    -webkit-mask-image: url('../images/story/photo-1.png');
    mask-image: url('../images/story/photo-1.png');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-size: contain;
  }

  h4, p {
    max-width: 1120px;
    margin: 0 auto;
  }

  h4 {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 32px;
    color: #221F1F;
  }

  p {
    font-size: 16px;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: normal;
    margin-bottom: 32px;
  }


}

.story__container
.content-black {
  background-color: #231F20;
  padding: 32px 0 0;
  margin-bottom: 0;

  .content {
    padding: 0 20px 48px;
    margin: 0;
  }

  h4, p {
    text-align: center;
    color: white;
  }

  .story-img-left {
    width: clamp(200px, 50%, 798px);
    aspect-ratio: 1;
    margin: 0 auto 24px;
    background-position: center;
    background-size: cover;

    -webkit-mask-image: url('../images/story/photo-1.png');
    mask-image: url('../images/story/photo-1.png');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-size: contain;
  }

  p:last-child {
    margin-bottom: 0;
  }
}


// @media only screen and (max-width: 1140px){

//   .storyimg-left,
//   .storyimg-right {
//     max-width: 400px;
//   }
// }

@media only screen and (min-width: 760px){

  .story__container {
    padding: 32px 0 0;
    display: flow-root;
    overflow-x: hidden;
    text-align: left;

    .content {
      padding: 80px 40px 80px;
    }

    h4, p {
      max-width: 1120px;
      margin: 0 auto;
    }

    .title {
      text-align: center;
      text-transform: uppercase;
      padding: 40px 0;
      color: #221F1F;
    }
    h4 {
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 32px;
      color: #221F1F;
    }

    p {
      font-size: 20px;
      font-family: "Lato", sans-serif;
      font-weight: 300;
      font-style: normal;
      margin-bottom: 48px;
    }

    .story-img-right {
      float: right;
      margin: 0 -8% 0 0;
    }
  }

  .story__container
  .content-black {
    background-color: #231F20;
    padding: 80px 40px 0;
    margin-bottom: 0;

    h4, p {
      text-align: right;
      color: white;
    }

    .story-img-left {
      content: " ";
      background-size: 100% 100%;
      float: left;
      margin: -12% 0 0 -8%;

      shape-outside: circle(50%);
      shape-margin: 2rem;
      -webkit-mask-image: url('../images/story/photo-1.png');
      mask-image: url('../images/story/photo-1.png');
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      mask-size: contain;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}
