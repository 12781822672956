@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.contact__container {
  width: 100%;
  max-width: 1440px;
  padding: 0 16px;
  margin: 0 auto;

  .page--title {
    text-transform: uppercase;
    text-align: center;
    margin: 25px 0 32px 0;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .contact-content__container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    // margin-bottom: 100px;
  }

  .contact-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contact-details {
    // margin-bottom: 32px;

    .social_link {
      margin-right: 20px;

      img {
        height: 30px;
      }
    }
  }

  .contact-socials {
    margin-bottom: 16px;
  }

  .contact-info {
    font-family: "Lato", sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: .875rem;
    letter-spacing: .5px;
  }

  .contact-description {
    font-size: 20px;
    font-weight: 300;
  }

  .contact-info,
  .contact-description {
    white-space: nowrap;
  }

  .map {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 760px) {
  .contact__container {
    
    .contact-content {
      text-align: center;
      gap: 32px;
    }

    .page--title {
      margin-bottom: 80px;
    }
  }
}

@media only screen and (min-width: 760px){

  .contact__container {
    padding: 0 48px;

    .page--title {
      margin-bottom: 100px;
    }

    .contact-content__container {
      height: calc(100vh - 320px);
      max-height: 500px;
      flex-direction: row;
      justify-content: space-between;
    }

    .map {
      width: auto;
      height: 100%;
    }
  }
}
