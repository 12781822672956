@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap');

.portfolio__container {
  padding: 32px 16px 50px;
  overflow: hidden;
  font-family: "Lato", sans-serif;

  a {
    text-decoration: none;
    width: fit-content;
  }

  .title {
    text-align: center;
    text-transform: uppercase;
    padding: 40px 0;
  }

  .link__container {
    width: 100%;
    max-width: 1080px;
    overflow-x: scroll;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px auto;
    gap: 24px;

    .link {
      text-transform: uppercase;
      cursor: pointer;
      font-weight: 300;
    }

    &.urban-view {
      justify-content: space-around;
    }
  }

  .wrapper__card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;
    max-width: 1200px;
    min-height: 60vh;
    margin: 0 auto;

    .card {
      width: 100%;
      max-width: 360px;
      height: 500px;
      border: none;

      .card--content {
        opacity: 0;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
      }

      &:hover {
        .card--content {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .card--thumbnail {
      width: 100%;
      height: 100%;
      filter: grayscale(100%);
      background-size: cover;
      background-position: center;
      position: absolute;
    }

    .card--thumbnail-empty {
      display: flex;
      justify-content: center;
      align-items: center;
      filter: none;
      background-color: #c3c3c3;
    }

    .card--thumbnail-empty img {
      filter: brightness(0) invert(1) contrast(20);
      width: 64%;
    }

    .card--content {
      height: 100%;
      width: 100%;
      visibility: hidden;
      display: flex;
      flex-direction: column;
      gap: 16px;
      justify-content: center;
      align-items: center;
      background-color: rgba(250, 250, 250, 0.8);
      z-index: 9;

      .card--content-img {
        width: 48px;
        height: auto;
      }

      .card--content-title {
        font-size: 24px;
        font-weight: 300;
        text-align: center;
        text-transform: uppercase;
        margin: 0;
        padding: 0 16px;
      }
    }

    .card--content-mobile {
      height: 100%;
      width: 100%;
      visibility: visible;
      display: flex;
      flex-direction: column;
      gap: 16px;
      justify-content: center;
      align-items: center;
      background-color: rgba(63, 90, 92, 0.64);
      z-index: 9;

      .card--content-img {
        width: 48px;
        height: auto;
      }

      .card--content-title {
        font-size: 24px;
        font-weight: 300;
        text-align: center;
        text-transform: uppercase;
        margin: 0;
        padding: 0 16px;
        color: white !important;
      }
    }
  }

  .custom-select {
    display: flex;
    align-items: center;
    margin: 40px auto;
    font-size: 1.15rem;
    padding: 8px 10px 8px 16px;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 2px;
    color: #000;
    cursor: pointer;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: normal;
    text-align: left;
    white-space: nowrap;
    width: auto;
  
    .chevron-icon {
      width: 1em;
      height: 1em;
      background-image: url("https://jfaprod300924.s3.eu-west-3.amazonaws.com/media/site/icons/arrow-chevron-up-down.svg");
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 12px;
    }
  }

  .list__wrapper {
    margin: 0 auto;
    max-width: 1200px;

    .list--title {
      text-transform: uppercase;
      font-size: 1.15rem;
      margin-bottom: 24px;
    }

    li {
      font-family: "Lato", sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 18px;
    }

    .list__container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 24px;
      padding-left: 18px !important;
    }

    summary {
      list-style: none;
    }

    details summary {
      cursor: pointer;
      transition: margin 150ms ease-out;
    }

    summary::-webkit-details-marker {
      display: none
    }

    details[open] summary {
      margin-bottom: 10px;
    }
  }
}

@media (min-width: 768px) {
  .portfolio__container {
    .link__container {
      width: 60%;
      overflow-x: auto;
    }
  }
}

.architecture-active-link {
  font-weight: 400 !important;
  color: #3F5A5C;
}

.interiors-active-link {
  font-weight: 400 !important;
  color: #5E6E60;
}

.lighting-active-link {
  font-weight: 400 !important;
  color: #946965;
}

.urban-active-link {
  font-weight: 400 !important;
  color: #C2885C;
}

.active-link-all {
  font-weight: 400 !important;
}

.leisure-link:hover,
.commercial-link:hover,
.residential-link:hover,
.link-all:hover {
  font-weight: 400 !important;
}

@media screen and (max-width: 480px) {
  .portfolio__container {

    .wrapper__card {
      .card--content {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 480px) {
  .portfolio__container {

    .wrapper__card {
      .card--content-mobile {
        display: none;
      }
    }
  }
}
