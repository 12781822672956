:root {
    --dark-grey: #333333;
    --medium-grey: #636363;
    --light-grey: #eeeeee;
    --ash: #f4f4f4;
    --primary-color: #861637;
    --nav-link: #b4b4b4;
    --white: #fff;
    --border: 1px solid var(--light-grey);
    --shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  
    --primary-text: #313131;
    --header-text: #616161;
    --secondary-text: #919191;
    --seperator: #e4e4e4;
    --bg-color: #F9F9F9;
  }
  