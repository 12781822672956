// @import "typography";
// @import "navbar";
// @import "color";

body {
  padding: 0 !important;
}

.filter-to-white {
  filter: brightness(0) invert(1) contrast(20);
  opacity: 0.5;
}

// animation zoom-infinite
@keyframes zoom-infinite {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

// ------------------------- header ---------------------------
.banner-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;

  .banner-img {
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
  }

  .banner-video-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;

    .banner-video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .scroll-btn-container {
    position: absolute;
    bottom: 20px;
    transform: translateX(-50%);
    animation: zoom-infinite 2s infinite;

    .scroll-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #eee;
      transition: all 0.3s ease-in-out;
      opacity: .8;

      &:hover {
        opacity: 1;
      }
    }
  }
}

// ------------------------- summary ---------------------------
.summary-container {
  height: 100vh;
  width: 100%;
  padding: 5em 0;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;

  .summary {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5em;

    .summary-link {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease-in-out;

      &:hover {
        .summary-image {
          opacity: 1;
        }
      }

      .summary-image {
        width: 150px;
        height: auto;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

// RESPONSIVE
@media (max-width: 768px) {
  .summary-container {
    .summary {
      flex-direction: column;
      gap: 2em;

      .summary-link {
        .summary-image {
          width: 200px;
        }
      }
    }
  }
}

// Mobile
@media (max-width: 480px) {

  .summary-container {
    .summary .summary-link .summary-image {
        width: 150px;
    }
  }
}

@media (min-width: 480px) {

  .banner-container  {
    .scroll-btn-container {
      left: 50%;
    }
  }
}
